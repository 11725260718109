<template>
    <b-row>
        <b-col cols="2">
            <zw-input-group v-model="inputVal.purchase_price"
                            name="purchase_price"
                            type="number"
                            :label-prefix="labelPrefix"
                            disable-label
                            :validate="{ required: true }"
            ></zw-input-group>
        </b-col>
        <b-col cols="2">
            <zw-input-group v-model="inputVal.sales_price"
                            name="sales_price"
                            :label-prefix="labelPrefix"
                            disable-label
                            :validate="{ required: true }"
            ></zw-input-group>
        </b-col>
        <b-col cols="2">
            <zw-date-group v-model="inputVal.start"
                           name="start"
                           :label-prefix="labelPrefix"
                           disable-label
                           :validate="{ required: true }"
            ></zw-date-group>
        </b-col>
        <b-col cols="2">
            <zw-date-group v-model="inputVal.end"
                           name="end"
                           :label-prefix="labelPrefix"
                           disable-label
                           :validate="{ required: true }"
            ></zw-date-group>
        </b-col>
        <b-col cols="2">
            <zw-input-group v-model="inputVal.min_days"
                            name="min_days"
                            type="number"
                            :label-prefix="labelPrefix"
                            disable-label
                            :validate="{ required: true }"
            ></zw-input-group>
        </b-col>
        <b-col cols="2">
            <b-button variant="danger" @click="remove()" :title="$t('common.title.delete')">
                <font-awesome-icon icon="trash"/>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>

export default {
    name: 'RoomPrice',
    props: {
        value: {
            type: Object,
            required: true
        },
        remove: {}
    },
    data() {
        return {
            labelPrefix: 'article.label.custom_price.',
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>