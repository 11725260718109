<template>
    <zw-sidebar @shown="shown" :title="$t('article.title.room')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-card :header="$t('article.group.basic')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <zw-input-group v-model="form.name"
                                                name="name"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.sku"
                                                name="sku"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group :label="$t(labelPrefix+'categories')">
                                    <multiselect
                                        v-model="form.category_ids"
                                        :options="Object.keys(getCategoryOptions()).map(cat=>parseInt(cat))"
                                        :multiple="true"
                                        :custom-label="opt => getCategoryOptions()[opt]">
                                    </multiselect>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.type"
                                                 :options="getTypes() | optionsVV"
                                                 name="type"
                                                 readonly
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['organizer']"
                                              :field="form.customFields['organizer']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['contact_partners']"
                                              :field="form.customFields['contact_partners']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.customValues['hotel']"
                                                 :name="'custom.'+form.customFields['hotel'].model+'.'+form.customFields['hotel'].name"
                                                 :label="form.customFields['hotel'].options.label"
                                                 :options="getHotelsList()"
                                                 valueField="id"
                                                 textField="name"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.vat"
                                                 :options="getVats() | optionsVL"
                                                 name="vat"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="12">
                                <zw-ckeditor v-model="form.description"></zw-ckeditor>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.prices')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="2">{{ $t('article.label.custom_price.purchase_price') }}</b-col>
                            <b-col cols="2">{{ $t('article.label.custom_price.sales_price') }}</b-col>
                            <b-col cols="2">{{ $t('article.label.custom_price.start') }}</b-col>
                            <b-col cols="2">{{ $t('article.label.custom_price.end') }}</b-col>
                            <b-col cols="2">{{ $t('article.label.custom_price.min_days') }}</b-col>
                        </b-row>
                        <room-price v-for="(custom_price, index) in form.custom_prices"
                                    :key="index"
                                    v-model="form.custom_prices[index]"
                                    :remove="function(){removeCustomPrice(index)}"
                        ></room-price>

                        <b-row>
                            <b-col cols="1">
                                <b-button variant="primary" @click="addCustomPrice">
                                    <font-awesome-icon icon="plus"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.options')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['capacity']"
                                              :field="form.customFields['capacity']"></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['capacity_child']"
                                              :field="form.customFields['capacity_child']"></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['double']"
                                              :field="form.customFields['double']"></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['area']"
                                              :field="form.customFields['area']"></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['other1']"
                                              :field="form.customFields['other1']"></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['other2']"
                                              :field="form.customFields['other2']"></custom-field>
                            </b-col>
                        </b-row>

                        <b-form-checkbox-group
                            v-model="form.customValues['options']"
                        >
                            <b-row>
                                <b-col sm="3" v-for="option in form.customFields['options'].options" :key="option">
                                    <b-form-checkbox
                                        :value="option"
                                    > {{ option }}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form-checkbox-group>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.label.photos')">
                    <b-card-text>
                        <zw-files-upload-group v-model="form.customValues['images']"
                                               ref="images"
                                               url="/hotels/upload"
                                               accepted-files="image/*"
                        ></zw-files-upload-group>
                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="3" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import RoomPrice from "../../components/booking/RoomPrice";

export default {
    components: {RoomPrice},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                type: 'simple',
                custom_prices: [],
            },
            form: {},
            labelPrefix: 'article.label.',
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle', 'getTypes', 'getCustomPrices']),
        ...mapGetters('Hotels', ['getHotelsList']),
        ...mapGetters('CommonData', ['getSettings', 'getUnits', 'getVats']),
        ...mapGetters('Category', ['getCategories', 'getCategoryOptions']),
        shown() {
            const hotelsList = this.$store.dispatch('Hotels/fetchHotelsList')
            const articles = this.$store.dispatch('Articles/fetchArticle', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptions')
            const articleTypes = this.$store.dispatch('Articles/fetchTypes')

            Promise.all([hotelsList, articles, categories, articleTypes])
                .then((values) => {
                    this.form = {
                        ...this.defaultForm,
                        ...{category_id: this.getSettings()['booking_room_category']},
                        ...JSON.parse(JSON.stringify(this.getArticle()))
                    }

                    this.form.custom_prices = this.getCustomPrices()

                    this.form.customValues['options'] = JSON.parse(this.form.customValues['options'] ?? null) || []
                    this.form.customValues['images'] = JSON.parse(this.form.customValues['images'] ?? null) || []

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    if (this.form.custom_prices.length == 0) {
                        this.form.custom_prices.push({})
                    }

                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    // These needed for remove old options
                    let options = []
                    Object.values(this.form.customFields['options'].options).forEach(option => {
                        if (this.form.customValues['options'].includes(option)) {
                            options.push(option)
                        }
                    })

                    this.$store.dispatch('Articles/saveArticle', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        addCustomPrice() {
            this.form.custom_prices.push({})
        },
        removeCustomPrice(index) {
            this.form.custom_prices = this.form.custom_prices.filter((data, i) => {
                return i != index
            })
        },
    }
}
</script>